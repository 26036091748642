// routes.js
import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Private from "../layout/PrivateRoutes";
import Auth from "../layout/Authentication";
import Loader from "../components/Loader";
import Public from "../layout/PublicRoutes"

const LazyHome = lazy(() => import("../pages/Homepage"));
const LazyUserList = lazy(() => import("../pages/UserList"));
const LazyDepartmentUsers = lazy(() => import("../pages/DepartmentUsers"));
// const LazyUserDetails = lazy(() => import("../pages/userDetails"));

const LazyALogin = lazy(() => import("../pages/UserLogin"));
const LazyContactSupport = lazy(()=> import("../pages/ContactSupport"));
const LazyRequests = lazy(() => import("../pages/Requests"));
const LazyDepartment_Admin = lazy(() => import("../pages/DepartmentAdmin.js"));
const LazyDepartmentUser_by_ID = lazy(() => import("../pages/DepartmentUsersByAdmin"));
const LazyRegular_Subscription = lazy(() => import("../pages/Subscription"));
const LazyInvoice = lazy(() => import("../pages/Invoice"));
const LazySupport = lazy(() => import("../pages/Support.js"));

export default function AllRoutes() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/login" element={<LazyALogin />} />
        <Route element={<Auth />}>
          <Route element={<Private />}>
            <Route path="/" element={<LazyHome />} />
            <Route path="/UserList" element={<LazyUserList />} />
            <Route path="/DepartmentUsers" element={<LazyDepartmentUsers />} />
            {/* <Route path="users/:id" element={<LazyUserDetails />} /> */}
            <Route path="/Request" element={<LazyRequests />} />
            <Route path="/DepartmentAdmin" element={<LazyDepartment_Admin />} />
            <Route path="/Department/User/:userId" element={<LazyDepartmentUser_by_ID />} />
            <Route path="/RegularSubscription" element={<LazyRegular_Subscription />} />
            <Route path="/Invoice" element={<LazyInvoice />} />
            <Route path="/support" element={<LazySupport/>} />
          </Route>
        </Route>
        <Route element={<Public />}>
          <Route path="/contact/support" element={<LazyContactSupport />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
