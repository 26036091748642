import React from 'react'
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import moment from "moment";

const Auth = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();

  const decodedToken = useMemo(() => {
    try {
      return token ? jwtDecode(token) : null;
    } catch (error) {
      return null;
    }
  }, [token]);

  const isValid = useMemo(() => {
    if (!decodedToken) {
      return false;
    }
    const expiry = decodedToken.exp * 1000; // Convert expiry time to milliseconds
    const currentDate = new Date().getTime();
    const isBefore = moment.unix(expiry).isAfter(moment.unix(currentDate));
    return isBefore  }, [decodedToken]);

  useEffect(() => {
    if (!isValid) {
      navigate("/login");
    }
  }, [isValid, navigate]);

  return isValid ? <Outlet /> : null;
};

export default Auth;

