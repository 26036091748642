// privateLayout.js
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import CustNavbar from '../components/CustNavbar';
import Footer from '../components/Footer';
import { useState } from 'react';
import AdminSidebar from '../components/Sidebar';

function Private() {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [issubMenuOpen, setsubMenuOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const subMenu = () => {
    setsubMenuOpen(!issubMenuOpen);
  }

  const location = useLocation();
  
  // Adjust this according to your default route path
  const isDefaultRoute = location.pathname === '/';

  return (
    <div className={`w-100 min-h-100 d-flex main-container position-relative ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      {isSidebarOpen && <div className="backdrop" onClick={toggleSidebar}></div>}
      <div className="w-100 min-h-100 d-flex">
        <AdminSidebar
          isOpen={isSidebarOpen}
          subMenu={subMenu}
          issubMenuOpen={issubMenuOpen}
          toggleSidebar={toggleSidebar}
        />
        <div className="content d-flex flex-col position-relative">
          <div className="content-inner position-relative">
            <div className="w-100 d-flex">
              <CustNavbar toggleSidebar={toggleSidebar} />
            </div>
            <div className="w-auto d-flex flex-col outlet-container">
              <div className={`px-3 py-3 ${isDefaultRoute ? 'default-route-class' : 'outlet-inner'}`}>
                <Outlet />
              </div>
              <div className="w-100 d-flex py-3 border-t-2">
                <div className="ps-0">
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Private;
