import React from "react";
import { Button, ModalBody } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Icon } from "@iconify/react";
import ImageElement from "./ImageElement";
import "./CustomNavbar.scss";
import "../index.css";
import { useDispatch } from "react-redux";
import { logout } from "../redux/loginSlice";
import { useState } from "react";
import CustomModal from "./CustomModal";
import ChangePasswordForm from "./ChangePasswordForm";

export default function AdminNavbar({ toggleSidebar }) {
  let dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  const [showChangePasswordModal, setChangePasswordModal] = useState(false);

  const handleChangePassword = () => {
    setChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setChangePasswordModal(false);
  };

  return (
    <div className="w-100">
      <div className="Container-fluid  bg-white">
        <div className="top-nav-container border-bottom-1 position-relative">

          <Navbar >
            <Nav className="ms-auto align-items-center">
              <ImageElement
                source={"soberity-logo.png"}
                alt="Profile"
                className="rounded-circle mr-2 border"
                style={{ width: "30px", height: "30px" }}
              />
              <NavDropdown id="collapsible-nav-dropdown" className="profile_main">
                <NavDropdown.Item onClick={handleChangePassword}>
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar>
        </div>

        <div className="px-3 py-2">
        </div>
      </div>
      <CustomModal
        show={showChangePasswordModal}
        onHide={handleCloseChangePasswordModal}
        title="Change Password"
        onCancel={handleCloseChangePasswordModal}
        hasFooter={false}
      >
        <ChangePasswordForm onCancel={handleCloseChangePasswordModal} />
      </CustomModal>
    </div>
  );
}
