import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import apiRequest from '../services/api';
import toast, { Toaster } from 'react-hot-toast';

const ChangePasswordForm = ({ onCancel  }) => {
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters long'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  const token = useSelector((state) => state.auth.token);

  // const handleSubmit = (values, { setSubmitting }) => {
  //   // Your logic to handle form submission
  //   console.log(values)
  //   setSubmitting(false);
  //   onCancel()
  // };

  const handleSubmit = async(values, { setSubmitting }) => {
    let params = {
      method: "PUT",
      endpoint: "change-password",
      headers: {"Content-Type": "application/json",

    "Authorization": `Bearer ${token}`
  },
      data: {
        old_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword
    }
    }

    try{
      await apiRequest(params).then((data)=>{
        if(data?.data?.status){
          
          setSubmitting(true);
          onCancel();
           toast.success('checking');
        }else{
          toast.error(data?.data?.message)
        }
      })
    }
      catch(error){
        toast.error('Password change Successfully!')
      }
      // setSubmitting(false);
    };

  return (
    <div>
      <Toaster
            position="top-center"
            reverseOrder={false}
          />
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="mb-3">
            <label htmlFor="oldPassword">Old Password</label>
            <Field type="password" name="oldPassword" className="form-control" />
            <ErrorMessage name="oldPassword" component="div" className="text-danger" />
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword">New Password</label>
            <Field type="password" name="newPassword" className="form-control" />
            <ErrorMessage name="newPassword" component="div" className="text-danger" />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Field type="password" name="confirmPassword" className="form-control" />
            <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
          </div>
          <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Change Password</button>
            <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
          </div>
        </Form>
      )}
    </Formik>
    </div>
  );
};

export default ChangePasswordForm;