import React, { useState } from 'react';
import ImageElement from './ImageElement';
import { Nav, NavItem, NavLink, Collapse, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Icon } from '@iconify/react';
import '../index.css'

export default function AdminSidebar({ isOpen, toggleSidebar }) {
  const location = useLocation();
  const [subMenuState, setSubMenuState] = useState({});
  const [activePath, setActivePath] = useState(location.pathname);

  const toggleSubMenu = (menu) => {
    setSubMenuState((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleNavItemClick = (path) => {
    setActivePath(path);
    // if (window.innerWidth < 768) {
    //   toggleSidebar();
    // }
  };

  return (
    <div className={`sidebar-container ${isOpen ? 'open' : 'collapsee'}`} id="sidebar">
      <div className='app_custom position-relative'>
        <Button className="Trasparent_primary_btn position-absolute right-[1px] top-[5px] z-[999999]" onClick={toggleSidebar}>
                <Icon icon="ci:hamburger-lg" className='text-2xl'/>
              </Button>
        <div className='position-relative'>
          <Nav className="flex-column">
            <div className="sidebar-header d-flex align-items-center mx-2">
              <h3 className='w-full mx-auto'>
                <ImageElement source={'sobrity-logo.png'} className='max-with-3 brand_logo' />
              </h3>
            </div>

            <div className='navigation-container'>
              <NavItem>
                <NavLink as={Link} to="/" onClick={() => handleNavItemClick('/')} className={`d-flex flex-row align-items-center ${activePath === '/' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    <Icon icon="carbon:dashboard" className='me-1' />
                  </span>
                  <span className='nav-label'>Dashboard</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink as={Link} to="/DepartmentAdmin" onClick={() => handleNavItemClick('/DepartmentAdmin')} className={`d-flex flex-row align-items-center ${activePath === '/DepartmentAdmin' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    <Icon icon="mingcute:department-line" className='me-1' />
                  </span>
                  <span className='nav-label'>Department Admins</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  as={Link}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSubMenu('users');
                  }}
                  className={`d-flex flex-row align-items-center sub-menu-main ${subMenuState.users ? 'open' : 'close'}`}
                >
                  <span className='d-flex align-items-center'>
                    <Icon icon="tabler:users" className='me-1' />
                  </span>
                  <span className='nav-label'>User Management</span>
                </NavLink>
                <Collapse in={subMenuState.users}>
                  <Nav className="flex-column w-100">
                    <NavItem className='w-100'>
                      <NavLink as={Link} to="/DepartmentUsers" onClick={() => handleNavItemClick('/DepartmentUsers')} className={`d-flex flex-row ${activePath === '/DepartmentUsers' ? 'active' : ''}`}>
                        <span className='d-flex align-items-center'>
                          <Icon icon="tabler:users" className='me-1' />
                        </span>
                        <span className='nav-label'>Department Users</span>
                        
                      </NavLink>
                    </NavItem>
                    <NavItem className='w-100'>
                      <NavLink as={Link} to="/UserList" onClick={() => handleNavItemClick('/UserList')} className={`d-flex flex-row ${activePath === '/UserList' ? 'active' : ''}`}>
                        <span className='d-flex align-items-center'>
                          <Icon icon="tabler:users" className='me-1' />
                        </span>
                        <span className='nav-label'>Normal Users</span>
                        
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </NavItem>

              <NavItem>
                <NavLink as={Link} to="/Request" onClick={() => handleNavItemClick('/Request')} className={`d-flex flex-row align-items-center ${activePath === '/Request' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    <Icon icon="charm:git-request" className='me-1' />
                  </span>
                  <span className='nav-label'>Department user Requests</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink as={Link} to="/RegularSubscription" onClick={() => handleNavItemClick('/RegularSubscription')} className={`d-flex flex-row align-items-center ${activePath === '/RegularSubscription' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    <Icon icon="eos-icons:product-subscriptions-outlined" className='me-1' />
                  </span>
                  <span className='nav-label'>Subscriptions Overview</span>
                </NavLink>
              </NavItem>

              <NavItem >
                <NavLink as={Link} to="/Invoice" onClick={() => handleNavItemClick('/Invoice')} className={`d-flex flex-row align-items-center ${activePath === '/Invoice' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    <Icon icon="vaadin:invoice" className='me-1' />
                  </span>
                  <span className='nav-label'>Invoice</span>
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink as={Link} to="/support" onClick={() => handleNavItemClick('/support')} className={`d-flex flex-row align-items-center ${activePath === '/Invoice' ? 'active' : ''}`}>
                  <span className='d-flex align-items-center'>
                    
                    <Icon icon="material-symbols-light:contact-support-outline-rounded"  className='me-1'/>
                  </span>
                  <span className='nav-label'>Support</span>
                </NavLink>
              </NavItem>
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}
