import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import persistReducer from 'redux-persist/es/persistReducer';
import authReducer from './loginSlice';
const rootReducer = combineReducers({ auth: authReducer });

const secretKey = 'QWERTYUIOPOIUYTREWQASDXCFVBNJKLKJHGFDF'; // Replace with your actual secret key

const encryptor = encryptTransform({
    secretKey,
    whitelist: ['auth'], // Encrypt  slice
});

const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
    reducer: persistedReducer,
});

export const Persistor = persistStore(Store);

export default Store;
