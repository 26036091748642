import React from 'react'
import { Provider } from "react-redux";
import "./App.scss";
import AllRoutes from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import Store, { Persistor } from "./redux/store";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Provider store={Store}>
        <PersistGate persistor={Persistor}>
        <Toaster position="top-center" reverseOrder={false} />
          <AllRoutes />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
