import React from 'react';
import { Outlet } from 'react-router-dom';
import CustNavbar from '../components/CustNavbar';
import Footer from '../components/Footer';

function Public() {
  return (
    <>
      <div className="public-content">
        <Outlet />
      </div>
    </>
  );
}

export default Public;
