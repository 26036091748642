import React from 'react'
import "./loader.scss";

export default function AdminLoader() {
    return (
        <div>
            <div className="loader">
                <div className="bar"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
                <div className="bar4"></div>
            </div>
        </div>
    )
}
