import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function CustomModal({ show, onHide, title, children, onCancel, onConfirm, hasFooter = true }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {hasFooter && (
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel || onHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default CustomModal;
